import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'grommet';
import FilterTabs from '../../components/filter-tabs/filter-tabs';
import DataList from '../../components/data-list/data-list';
import {
  loadProducts,
  setOrderByProducts
} from '../../actions/products-actions';
import formattedDateApi from '../../utils/formatted-date-api';
import formatMoney from '../../utils/format-money';
import LocalStorageService from '../../services/localStorageService';

function SoldedProductsDataList() {
  const dispatch = useDispatch();
  const localStorageService = LocalStorageService.getService();
  const companySelected = JSON.parse(localStorageService.getCompanyBranch());
  const productsList = useSelector(state => state.products.productsList);
  const produtctsForSale = useSelector(
    state => state.products.produtctsForSale
  );
  const dataInicial = formattedDateApi(
    useSelector(state => state.products.dataInicial)
  );
  const dataFinal = formattedDateApi(
    useSelector(state => state.products.dataFinal)
  );
  const orderBy = useSelector(state => state.products.orderBy);

  useEffect(() => {
    if (dataInicial && dataFinal) {
      dispatch(
        loadProducts({
          empresaId: companySelected.id,
          produtctsForSale,
          dataInicial,
          dataFinal,
          orderBy
        })
      );
    }
  }, [
    dispatch,
    companySelected.id,
    dataInicial,
    dataFinal,
    orderBy,
    produtctsForSale
  ]);

  const actionButtonsSoldedProducts = [
    {
      id: 1,
      label: 'Qtdade',
      active: true,
      actionCallback: () => {
        dispatch(setOrderByProducts('quantidade'));
      }
    },
    {
      id: 2,
      label: 'Valor',
      active: false,
      actionCallback: () => {
        dispatch(setOrderByProducts('valor'));
      }
    },
    {
      id: 3,
      label: 'Lucro',
      active: false,
      actionCallback: () => {
        dispatch(setOrderByProducts('lucro'));
      }
    }
  ];

  const dataListRows = productsList.map(item => {
    const {
      produtoId,
      quantidade,
      valorTotal,
      descricao,
      lucroBrutoMoeda,
      lucroBrutoPorcento
    } = item;

    return {
      id: produtoId,
      name: `Quantidade: ${quantidade}`,
      value: formatMoney(valorTotal),
      description: descricao,
      info: `Lucro bruto: ${formatMoney(
        lucroBrutoMoeda
      )}(${lucroBrutoPorcento}%)`
    };
  });

  return (
    <>
      <Box margin={{ top: 'medium' }}>
        <FilterTabs actionButtonsList={actionButtonsSoldedProducts} />
      </Box>
      <DataList list={dataListRows} />
    </>
  );
}

export default SoldedProductsDataList;
