import isValidDate from './is-valid-date';

const formattedDateApi = date => {
  if (!isValidDate(date)) return date;
  const d = new Date(date);
  return [d.getFullYear(), d.getMonth() + 1, d.getDate()]
    .map(n => (n < 10 ? `0${n}` : `${n}`))
    .join('-');
};

export default formattedDateApi;
