import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DataList from '../../components/data-list/data-list';
import formatMoney from '../../utils/format-money';

const flowTabsPropTypes = {
  activeTab: PropTypes.number.isRequired
};

const tabDataName = {
  1: 'composicaoVendas',
  2: 'composicaoRecebimentos',
  3: 'composicaoSuprimentos'
};

function CashFlowTabs({ activeTab }) {
  const cashFlow = useSelector(state => state.cashRegisters.cashFlow);

  const tabData = cashFlow[tabDataName[activeTab]];

  const dataListRows =
    tabData.map((item, index) => {
      const { titulo, percentual, valor, tipo, descricao } = item;
      return {
        id: index,
        name: activeTab === 3 ? descricao : titulo,
        info: activeTab === 3 ? `Tipo: ${tipo}` : `Percentual: ${percentual}%`,
        value: formatMoney(valor)
      };
    }) || [];

  return <DataList darkMode list={dataListRows} />;
}

CashFlowTabs.propTypes = flowTabsPropTypes;

export default CashFlowTabs;
