import React from 'react';
import { ResponsiveContext, Box } from 'grommet';
import { ResponsiveBar } from '@nivo/bar';
import barChartTheme from '../../constants/bar-chart-theme';

const BuildSolutionsBarChart = ({
  data,
  keys,
  indexBy,
  darkMode,
  onClick,
  tooltip,
  isInteractive,
  axisBottom
}) => {
  barChartTheme.axis.ticks.text.fill = darkMode ? '#163347' : '#fff';

  const customAxisBottom = axisBottom || {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legendPosition: 'middle',
    legendOffset: 32,
    color: 'white'
  };

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          height={size === 'small' ? 'medium' : 'large'}
          width={size === 'small' ? 'large' : 'large'}
        >
          <ResponsiveBar
            enableGridY={false}
            enableGridX={false}
            data={data}
            keys={keys}
            axisBottom={customAxisBottom}
            indexBy={indexBy}
            margin={{
              top: 16,
              right: 16,
              bottom: axisBottom ? 100 : 32,
              left: 16
            }}
            padding={0.3}
            colors="#F34642"
            axisTop={null}
            axisRight={null}
            axisLeft={null}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"
            motionStiffness={90}
            motionDamping={15}
            animate
            borderRadius={5}
            theme={barChartTheme}
            groupMode={keys.length > 1 ? 'grouped' : 'stacked'}
            enableLabel={false}
            onClick={onClick}
            isInteractive={!!tooltip}
            tooltip={tooltip}
          />
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default BuildSolutionsBarChart;
