import {
  getTaxInvoicesNfe,
  getTaxInvoicesCfe,
  getTaxInvoicesTotal
} from '../api';

export const loadTaxInvoicesNfe = params => {
  return dispatch => {
    getTaxInvoicesNfe(params)
      .then(response => {
        dispatch({
          type: 'LOAD_TAX_INVOICES_NFE_SUCCESS',
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: 'LOAD_TAX_INVOICES_NFE_FAILURE',
          payload: error
        });
      });
  };
};

export const loadTaxInvoicesCfe = params => {
  return dispatch => {
    getTaxInvoicesCfe(params)
      .then(response => {
        dispatch({
          type: 'LOAD_TAX_INVOICES_CFE_SUCCESS',
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: 'LOAD_TAX_INVOICES_CFE_FAILURE',
          payload: error
        });
      });
  };
};

export const loadTaxInvoicesTotal = params => {
  return dispatch => {
    getTaxInvoicesTotal(params)
      .then(response => {
        dispatch({
          type: 'LOAD_TAX_INVOICES_TOTAL_SUCCESS',
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: 'LOAD_TAX_INVOICES_TOTAL_FAILURE',
          payload: error
        });
      });
  };
};

export const setTaxInvoicesType = params => {
  return {
    type: 'SET_TAX_INVOICES_TYPE',
    payload: params
  };
};
