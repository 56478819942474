import localStorageKeys from '../constants/local-storage-keys';

const LocalStorageService = (function() {
  let service = null;
  function getService() {
    if (!service) {
      service = this;
      return service;
    }
    return service;
  }

  const getAccessToken = () => {
    return localStorage.getItem(localStorageKeys.JWT_TOKEN);
  };
  const setAccessToken = token => {
    localStorage.setItem(localStorageKeys.JWT_TOKEN, token);
  };

  const getRefreshToken = () => {
    return localStorage.getItem(localStorageKeys.JWT_REFRESH_TOKEN);
  };
  const setRefreshToken = refreshToken => {
    localStorage.setItem(localStorageKeys.JWT_REFRESH_TOKEN, refreshToken);
  };

  const getUserInfo = () => {
    return localStorage.getItem(localStorageKeys.USER_INFO);
  };
  const setUserInfo = userInfo => {
    return localStorage.setItem(localStorageKeys.USER_INFO, userInfo);
  };

  const getCompanyBranch = () => {
    return localStorage.getItem(localStorageKeys.COMPANY_BRANCH);
  };
  const setCompanyBranch = companyBranch => {
    return localStorage.setItem(localStorageKeys.COMPANY_BRANCH, companyBranch);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem(localStorageKeys.JWT_TOKEN);
    localStorage.removeItem(localStorageKeys.JWT_REFRESH_TOKEN);
    localStorage.removeItem(localStorageKeys.USER_INFO);
    localStorage.removeItem(localStorageKeys.COMPANY_BRANCH);
  };

  const hasRequiredCookies = () => {
    return !!(
      localStorage.getItem(localStorageKeys.JWT_TOKEN) &&
      localStorage.getItem(localStorageKeys.JWT_REFRESH_TOKEN) &&
      localStorage.getItem(localStorageKeys.USER_INFO) &&
      localStorage.getItem(localStorageKeys.COMPANY_BRANCH)
    );
  };

  return {
    getService,
    getAccessToken,
    setAccessToken,
    getRefreshToken,
    setRefreshToken,
    getCompanyBranch,
    setCompanyBranch,
    getUserInfo,
    setUserInfo,
    hasRequiredCookies,
    clearLocalStorage
  };
})();

export default LocalStorageService;
