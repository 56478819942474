import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';

const offset = 10;

export default function DataList(props) {
  const { list, darkMode } = props;
  const listLength = list.length;

  const [listData, setListData] = useState([]);
  const [limit, setLimit] = useState(offset);

  useEffect(() => {
    setListData(list.slice(0, limit));
  }, [list, limit]);

  const onMore = () => {
    const limitOffset = limit + offset;
    if (limitOffset >= listLength) return;
    setLimit(limit + offset);
  };

  return (
    <Box className="mobile-wrapper">
      <Box direction="column" margin={{ vertical: 'medium' }}>
        {listData.map((listItem, index) => {
          const bordeBottomColor = darkMode ? 'light-1' : 'light-3';
          const textColor = darkMode ? 'light-1' : 'dark-1';
          const borderBottom =
            listLength === index + 1
              ? { size: '0' }
              : { color: bordeBottomColor, side: 'bottom', size: 'xsmall' };
          return (
            <Box
              direction="row"
              border={borderBottom}
              pad={{ vertical: '1rem' }}
              align="center"
              justify="between"
            >
              <Box width={{ max: '80%' }}>
                <Text size="small" weight={600} color={textColor}>
                  {listItem.description}
                </Text>
                <Text size="small" weight={400} color={textColor}>
                  {listItem.name}
                </Text>
                <Box direction="row" justify="between">
                  <Text size="xsmall" weight={400} color={textColor}>
                    {listItem.info}
                  </Text>
                  <Text size="xsmall" weight={400} color={textColor}>
                    {listItem.profit}
                  </Text>
                </Box>
              </Box>
              <Box width={{ max: '25%' }}>
                <Text size="small" weight={400} color={textColor}>
                  {listItem.value}
                </Text>
              </Box>
            </Box>
          );
        })}

        {listLength ? (
          <>
            <Box align="center" margin="large">
              {listLength > limit + offset && (
                <Box
                  border={
                    darkMode
                      ? { side: 'bottom', size: 'xsmall', color: 'white' }
                      : { side: 'bottom', size: 'xsmall', color: 'brand' }
                  }
                  onClick={onMore}
                >
                  <Text
                    size="small"
                    color={darkMode ? 'white' : 'brand'}
                    weight={300}
                  >
                    Carregar mais
                  </Text>
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Box margin="xlarge">
            <Text textAlign="center" weight={300}>
              Sua pesquisa não retornou nenhum resultado
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
}

DataList.defaultProps = {
  list: [],
  darkMode: false
};

DataList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      info: PropTypes.string,
      value: PropTypes.string
    })
  ),
  darkMode: PropTypes.bool
};
