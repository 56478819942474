import axiosGlobalInstance from './axios-instance';
import uriApi from '../config/uriApi';

const getUser = () => {
  return axiosGlobalInstance({
    method: 'GET',
    url: `${uriApi}/api/me`
  });
};

export default getUser;
