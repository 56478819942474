import { getProducts } from '../api';

export const loadProducts = params => {
  return dispatch => {
    getProducts(params)
      .then(response => {
        dispatch({
          type: 'LOAD_PRODUCTS_SUCCESS',
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: 'LOAD_PRODUCTS_FAILURE',
          payload: error
        });
      });
  };
};

export const setProductsDate = params => {
  return {
    type: 'SET_PRODUCTS_DATE',
    payload: params
  };
};

export const setOrderByProducts = params => {
  return {
    type: 'SET_ORDER_BY_PRODUCTS',
    payload: params
  };
};

export const setProductsSale = params => {
  return {
    type: 'SET_PRODUCTS_SALE',
    payload: params
  };
};
