const INITIAL_STATE = {
  name: '',
  email: '',
  role: '',
  companies: [],
  errors: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_USER_DATA_SUCCESS': {
      const {
        usuario: { nome: name, email, role, empresas: companies }
      } = action.payload.data;
      return { ...state, name, email, role, companies };
    }
    case 'LOAD_USER_DATA_FAILURE': {
      return { ...state };
    }
    default:
      return state;
  }
}
