const barChartTheme = {
  fontSize: 13,
  tooltip: {
    container: {
      background: '#2C3746',
      color: '#fff'
    }
  },
  axis: {
    ticks: {
      line: {
        stroke: '#FFFFFF'
      },
      text: {
        fill: '#FFFFFF'
      }
    }
  }
};
export default barChartTheme;
