import axiosGlobalInstance from './axios-instance';
import uriApi from '../config/uriApi';

const getProducts = params => {
  const {
    produtctsForSale,
    empresaId,
    dataInicial,
    dataFinal,
    orderBy
  } = params;
  const url = produtctsForSale
    ? `${uriApi}/api/v1/produtos/${empresaId}/${dataInicial}/${dataFinal}/vendas?orderby=${orderBy}`
    : `${uriApi}/api/v1/produtos/${empresaId}/${dataInicial}/${dataFinal}/cancelados`;
  return axiosGlobalInstance({
    method: 'GET',
    url
  });
};

export default getProducts;
