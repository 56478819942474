import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';

import './build-solutions-progress-bar.css';

function BuildSolutionsProgressBar(props) {
  const { percentage, label } = props;
  const validPercentage = isNaN(percentage) ? 0 : percentage;
  return (
    <Box margin={{ vertical: 'medium' }}>
      <Box fill direction="row" align="center">
        <Box className="progress-bar">
          <Box
            className="filler"
            background="accent-3"
            style={{ width: `${validPercentage}%` }}
          />
        </Box>
        <Box pad={{ left: 'medium' }}>
          <Text color="accent-3" textAlign="center" weight={400}>
            {`${validPercentage}%`}
          </Text>
        </Box>
      </Box>
      <Box>
        <Text color="accent-3" textAlign="center" weight={400}>
          {label}
        </Text>
      </Box>
    </Box>
  );
}

BuildSolutionsProgressBar.defaultProps = {
  percentage: 0,
  label: 0
};

BuildSolutionsProgressBar.propTypes = {
  percentage: PropTypes.number,
  label: PropTypes.number
};

export default BuildSolutionsProgressBar;
