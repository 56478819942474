import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

export default function DarkContent(props) {
  const { children } = props;
  return (
    <Box
      align="center"
      pad={{ vertical: 'small', horizontal: 'large' }}
      flex={{ grow: 1, shrink: 0 }}
    >
      {children}
    </Box>
  );
}

DarkContent.defaultProps = {
  children: null
};

DarkContent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element)
};
