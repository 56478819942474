import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ResponsiveContext, Text, Box } from 'grommet';
import HeadingPage from '../../components/heading/heading-page';
import { loadUserData } from '../../actions/user-actions';
import background from '../../assets/full-cover-background.png';
import LocalStorageService from '../../services/localStorageService';

function CompanyBranches({ pageTitle }) {
  const companyBranchesList = useSelector(state => state.user.companies);
  const name = useSelector(state => state.user.name);
  const email = useSelector(state => state.user.email);
  const role = useSelector(state => state.user.role);
  const localStorageService = LocalStorageService.getService();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(loadUserData());
  }, [dispatch]);

  const handleSetCompany = companyBranch => {
    localStorageService.setCompanyBranch(JSON.stringify(companyBranch));
    localStorageService.setUserInfo(JSON.stringify({ name, email, role }));
    history.push('/fluxo-de-caixa');
  };

  return (
    <ResponsiveContext.Consumer>
      {size => {
        const verticalPad = size === 'small' ? 'xlarge' : 'large';
        return (
          <Box
            pad={{ horizontal: 'medium' }}
            background={`url(${background})`}
            className="module-wrapper"
            justify="start"
            align="center"
            animation="fadeIn"
          >
            <HeadingPage text={pageTitle} actionButton={() => {}} />
            <Box pad={{ horizontal: 'large' }} align="center">
              {companyBranchesList.map(companyBranche => (
                <Box
                  background={{ color: 'brand', opacity: true }}
                  pad={{ horizontal: 'small', vertical: verticalPad }}
                  margin={{ horizontal: 'medium', vertical: 'medium' }}
                  round="small"
                  align="center"
                  width="medium"
                  key={companyBranche.id}
                  flex={{ shrink: 0 }}
                  onClick={() => handleSetCompany(companyBranche)}
                  hoverIndicator
                >
                  <Text
                    textAlign="center"
                    weight={700}
                    size="large"
                    color="white"
                  >
                    {companyBranche.razaoSocial}
                  </Text>
                  <Text
                    weight={300}
                    color="white"
                  >{`CNPJ: ${companyBranche.cnpj}`}</Text>
                </Box>
              ))}
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}

CompanyBranches.propTypes = {
  pageTitle: PropTypes.string.isRequired
};

export default CompanyBranches;
