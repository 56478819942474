import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ResponsiveContext,
  TextInput,
  Box,
  Button,
  Image,
  Text
} from 'grommet';
import { Hide, View } from 'grommet-icons';
import { getUser } from '../../actions/account-actions';
import LocalStorageService from '../../services/localStorageService';

import logo from '../../assets/logomarca.png';
import background from '../../assets/full-cover-background.png';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [reveal, setReveal] = useState(false);
  const { token } = useSelector(state => state.auth);
  const { refreshToken } = useSelector(state => state.auth);
  const { errors } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogin = () => {
    dispatch(getUser({ email, senha: password }));
  };

  if (token) {
    const localStorageService = LocalStorageService.getService();
    localStorageService.setAccessToken(token);
    localStorageService.setRefreshToken(refreshToken);
    history.push('/unidades');
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          pad={{ horizontal: 'large' }}
          background={`url(${background})`}
          className="module-wrapper"
          justify="center"
          align="center"
          animation="fadeIn"
        >
          <Box
            background="white"
            pad={{ horizontal: 'medium', vertical: 'medium' }}
            round="medium"
            align="center"
            width="fit-content"
          >
            <Box margin={{ vertical: 'medium' }} width="234px">
              <Image src={logo} />
            </Box>
            <Box>
              <Box
                direction="row"
                margin={{ vertical: 'small' }}
                align="center"
              >
                <TextInput
                  placeholder="E-mail"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  size="small"
                />
              </Box>
              <Box
                direction="row"
                margin={{ vertical: 'small' }}
                align="center"
                round="24px"
                border
              >
                <TextInput
                  plain
                  placeholder="Senha"
                  type={reveal ? 'text' : 'password'}
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  size="small"
                />
                <Box pad={{ right: 'medium' }}>
                  <Button
                    icon={
                      reveal ? <View size="medium" /> : <Hide size="medium" />
                    }
                    plain
                    onClick={() => setReveal(!reveal)}
                  />
                </Box>
              </Box>
              {errors.length > 0 && (
                <Box margin={{ vertical: 'small' }}>
                  {errors.map(error => (
                    <Box margin={{ bottom: 'small' }}>
                      <Text
                        color="status-error"
                        size="small"
                        textAlign={errors.length > 1 ? 'left' : 'center'}
                      >
                        - {error}
                      </Text>
                    </Box>
                  ))}
                </Box>
              )}
              <Box margin={{ vertical: 'small' }} align="center" height="45px">
                <Button
                  primary
                  color="focus"
                  label="ENTRAR"
                  fill
                  onClick={handleLogin}
                  className="uppercase-button"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
}

export default Login;
