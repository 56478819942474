import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Text } from 'grommet';
import { Menu } from 'grommet-icons';
import LayerMenu from '../layer-menu/layer-menu';
import RoutesMap from '../../main/routes/routes-map';

import './primary-menu.css';

function PrimaryMenu() {
  const location = useLocation();
  const { pathname } = location;
  let showMenuAccordingPath = undefined;

  const [openLayerMenu, setOpenLayerMenu] = React.useState(false);
  const onOpenLayerMenu = () => setOpenLayerMenu(true);
  const onCloseLayerMenu = () => setOpenLayerMenu(false);

  for (let key in RoutesMap) {
    if (RoutesMap[key].path === pathname) {
      showMenuAccordingPath = RoutesMap[key].showNavigation;
    }
  }

  return (
    <>
      {showMenuAccordingPath ? (
        <Box className="main-menu" background="white" elevation="large">
          <Box className="mobile-wrapper">
            <Box
              direction="row"
              justify="around"
              fill="horizontal"
              background="white"
            >
              {Object.keys(RoutesMap).map((item, index) => {
                const route = RoutesMap[item];
                const currentRouteRendering = location.pathname === route.path;
                const activeMenuStyle = {};
                activeMenuStyle.color = currentRouteRendering
                  ? 'brand'
                  : 'dark-3';
                activeMenuStyle.border = currentRouteRendering
                  ? { color: 'brand', side: 'bottom', size: 'small' }
                  : null;

                if (!route.primaryMenu) return null;
                return (
                  <Box
                    key={item}
                    direction="column"
                    align="center"
                    border={activeMenuStyle.border}
                    flex
                  >
                    <Link to={route.path}>
                      <Box align="center">
                        {route.primaryMenu.icon({
                          color: activeMenuStyle.color
                        })}
                      </Box>
                      <Box>
                        <Text
                          size="12px"
                          weight="bold"
                          color={activeMenuStyle.color}
                          margin={{ top: 'xsmall' }}
                        >
                          {route.linkText}
                        </Text>
                      </Box>
                    </Link>
                  </Box>
                );
              })}
              <Box
                direction="column"
                align="center"
                justify="center"
                flex
                onClick={() => {
                  onOpenLayerMenu();
                }}
              >
                <Menu color="dark-3" />
              </Box>
              <LayerMenu
                openLayerMenu={openLayerMenu}
                onCloseLayerMenu={onCloseLayerMenu}
              />
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default PrimaryMenu;
