import axiosGlobalInstance from './axios-instance';
import uriApi from '../config/uriApi';

const getFinancialTotal = params => {
  const { empresaId, mesReferencia } = params;
  return axiosGlobalInstance({
    method: 'GET',
    url: `${uriApi}/api/v1/contas-pagar/${empresaId}/${mesReferencia}/totalizador`
  });
};

export default getFinancialTotal;
