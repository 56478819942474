const INITIAL_STATE = {
  lastSynchro: '',
  cashRegister: { id: 0, value: 'Caixa' },
  cashRegistersList: [],
  cashFlow: {
    composicaoVendas: [],
    composicaoRecebimentos: [],
    composicaoSuprimentos: []
  },
  billsToPay: [{}]
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_CASH_REGISTERS_SUCCESS': {
      return {
        ...state,
        cashRegistersList: action.payload.data,
        cashRegister: action.payload.data[0]
      };
    }
    case 'LOAD_CASH_REGISTERS_FAILURE':
      return { ...state };

    case 'LOAD_CASH_FLOW_SUCCESS': {
      return {
        ...state,
        cashFlow: action.payload.data
      };
    }
    case 'LOAD_CASH_FLOW_FAILURE':
      return { ...state };

    case 'LOAD_BILLS_TO_PAY_SUCCESS': {
      return {
        ...state,
        billsToPay: action.payload.data
      };
    }
    case 'LOAD_BILLS_TO_PAY_FAILURE':
      return { ...state };

    case 'SET_CASH_REGISTER': {
      return { ...state, cashRegister: action.payload };
    }
    case 'GET_LAST_SYNCHRO_SUCCESS': {
      return { ...state, lastSynchro: action.payload.data.sincronizadoEm };
    }
    case 'GET_LAST_SYNCHRO_FAILURE':
      return { ...state };

    default:
      return state;
  }
}
