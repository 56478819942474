import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import { Like, Dislike } from 'grommet-icons';

export default function StatusIndicator(props) {
  const { positive } = props;

  return (
    <Box
      direction="row"
      margin={{ vertical: 'large' }}
      fill="horizontal"
      justify="center"
      className="status-indicator"
      align="center"
    >
      {positive && (
        <Box
          direction="row"
          pad={{ horizontal: 'large', vertical: 'small' }}
          border={{ color: 'status-ok', size: 'small' }}
          align="center"
          round="xlarge"
        >
          <Box pad={{ horizontal: 'small' }}>
            <Text color="status-ok" size="large">
              Positivo
            </Text>
          </Box>
          <Box pad="small">
            <Like color="status-ok" size="22px" />
          </Box>
        </Box>
      )}

      {!positive && (
        <Box
          direction="row"
          pad={{ horizontal: 'large', vertical: 'small' }}
          border={{ color: 'status-critical', size: 'small' }}
          align="center"
          round="xlarge"
        >
          <Box pad={{ horizontal: 'small' }}>
            <Text color="status-critical" size="large">
              Negativo
            </Text>
          </Box>
          <Box pad="small">
            <Dislike color="status-critical" size="22px" />
          </Box>
        </Box>
      )}
    </Box>
  );
}

StatusIndicator.defaultProps = {
  positive: true
};
StatusIndicator.propTypes = {
  positive: PropTypes.bool
};
