import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import LocalStorageService from '../../services/localStorageService';
import PrimaryMenu from '../../components/primary-menu/primary-menu';

function RouteWrapper({ redirectTo, isPrivate, component, ...rest }) {
  const localStorageService = LocalStorageService.getService();
  const hasAllRequiredCookies = localStorageService.hasRequiredCookies();
  if (!hasAllRequiredCookies && isPrivate) return <Redirect to={redirectTo} />;

  return (
    <>
      <Route {...rest}>{component}</Route>
      <PrimaryMenu />
    </>
  );
}

RouteWrapper.propTypes = {
  redirectTo: PropTypes.string,
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {
  redirectTo: '/',
  isPrivate: false
};

export default RouteWrapper;
