import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsiveContext, Box, Text, Select } from 'grommet';
import { FormSchedule } from 'grommet-icons';
import HeadingPage from '../../components/heading/heading-page';
import PageLayout from '../../components/page-layout/page-layout';
import DarkContent from '../../components/page-layout/dark-content';
import LightContent from '../../components/page-layout/light-content';
import BuildSolutionsBarChart from '../../components/build-solutions-bar-chart/build-solutions-bar-chart';

import { loadSalesByDate, setRangeOfDays } from '../../actions/sales-actions';
import formatMoney from '../../utils/format-money';
import LocalStorageService from '../../services/localStorageService';

const CustomTick = data => tick => {
  const info = data.find(x => x.titulo === tick.value) || {};

  return (
    <g transform={`translate(${tick.x - 10},${tick.y + 35})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fill: '#333',
          fontSize: 10,
          transform: 'rotate(-45deg)',
          paddingBottom: 100,
          width: 100,
          height: 100
        }}
      >
        {formatMoney(info.valor)}
      </text>
    </g>
  );
};

function Sales(props) {
  const dispatch = useDispatch();
  const localStorageService = LocalStorageService.getService();
  const companySelected = JSON.parse(localStorageService.getCompanyBranch());
  const salesListSummary = useSelector(state => state.sales.salesListSummary);
  const salesListComparative = useSelector(
    state => state.sales.salesListComparative
  );

  const rangeOfDays = useSelector(state => state.sales.rangeOfDays);
  const { pageTitle } = props;

  useEffect(() => {
    dispatch(
      loadSalesByDate({ empresaId: companySelected.id, dias: rangeOfDays.id })
    );
  }, [dispatch, companySelected.id, rangeOfDays.id]);

  const axisBottom = {
    renderTick: CustomTick(salesListComparative),
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 0,
    tickRotation: -55,
    legend: '',
    legendOffset: 0
  };

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <PageLayout>
          <DarkContent>
            <Box className="mobile-wrapper">
              <HeadingPage text={pageTitle} />

              <Box align="center">
                <Box
                  className="custom-control-radius"
                  margin={{ top: 'medium' }}
                  width={{ max: '9.5rem' }}
                >
                  <Select
                    options={[
                      { id: 1, value: 'Hoje' },
                      { id: 3, value: 'Últimos 3 dias' },
                      { id: 7, value: 'Últimos 7 dias' }
                    ]}
                    value={rangeOfDays}
                    labelKey="value"
                    valueKey="id"
                    onChange={({ option }) => dispatch(setRangeOfDays(option))}
                    margin={{ vertical: 'small' }}
                    size="xsmall"
                    icon={<FormSchedule size="1.25rem" />}
                  />
                </Box>
              </Box>

              <Box
                direction="row"
                fill="horizontal"
                justify="center"
                pad={{ vertical: 'small' }}
                wrap
              >
                {salesListSummary.map(sale => (
                  <Box pad="medium" flex={{ grow: 0, shrink: 1 }}>
                    <Text weight={400} size="xsmall">
                      {sale.titulo}
                    </Text>
                    <Text size="small">{formatMoney(sale.valor)}</Text>
                  </Box>
                ))}
              </Box>
            </Box>
          </DarkContent>
          <LightContent>
            <Box margin={{ top: 'medium' }}>
              <Box align="center">
                <BuildSolutionsBarChart
                  data={salesListComparative}
                  keys={['valor']}
                  indexBy="titulo"
                  darkMode
                  axisBottom={axisBottom}
                />
              </Box>
            </Box>
          </LightContent>
        </PageLayout>
      )}
    </ResponsiveContext.Consumer>
  );
}

Sales.propTypes = {
  pageTitle: PropTypes.string.isRequired
};

export default Sales;
