import React from 'react';
import PropTypes from 'prop-types';
import DayPicker, { DateUtils } from 'react-day-picker';
import { Box, DropButton } from 'grommet';
import { Calendar } from 'grommet-icons';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/pt-br';

import 'react-day-picker/lib/style.css';
import './calendar-drop-button.css';

function CalendarDropButton(props) {
  const { from, to, onSelectDate } = props;
  const handleDayClick = day => {
    const range = DateUtils.addDayToRange(day, { from, to });
    onSelectDate({ from: range.from, to: range.to });
  };

  const modifiers = { start: from, end: to };
  let placeholderDateMessage = '';
  if (!from && !to) placeholderDateMessage = 'Escolha período Inicial';
  if (from && !to) placeholderDateMessage = 'Escolha período final';
  if (from && to)
    placeholderDateMessage = `${from.toLocaleDateString()} - ${to.toLocaleDateString()}`;

  return (
    <Box className="calendar-drop-button">
      <DropButton
        color="white"
        icon={<Calendar size="16px" />}
        label={placeholderDateMessage}
        dropAlign={{ top: 'bottom' }}
        alignSelf="center"
        dropContent={
          <DayPicker
            className="Selectable"
            numberOfMonths={1}
            selectedDays={[from, { from, to }]}
            modifiers={modifiers}
            onDayClick={handleDayClick}
            localeUtils={MomentLocaleUtils}
            locale="pt-br"
          />
        }
      />
    </Box>
  );
}

CalendarDropButton.defaultProps = {
  from: { length: 7 },
  to: { length: 7 }
};

CalendarDropButton.propTypes = {
  from: PropTypes.shape({ length: PropTypes.number }),
  to: PropTypes.shape({ length: PropTypes.number }),
  onSelectDate: PropTypes.func.isRequired
};

export default CalendarDropButton;
