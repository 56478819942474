const formatOptionsFromApi = options => {
  try {
    if (!options.length) return [{ id: 0, value: 'Nenhum caixa encontado' }];

    return options.map(({ caixaId, descricao }) => ({
      id: caixaId,
      value: descricao
    }));
  } catch (e) {
    return [{ id: 0, value: 'Caixa' }];
  }
};

export default formatOptionsFromApi;
