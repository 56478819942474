import axiosGlobalInstance from './axios-instance';
import uriApi from '../config/uriApi';

const getSalesByDate = params => {
  const { empresaId, dias } = params;
  return axiosGlobalInstance({
    method: 'GET',
    url: `${uriApi}/api/v1/caixas/empresa/${empresaId}/movimentacao/${dias}/dia`
  });
};

export default getSalesByDate;
