import { postLoginData } from '../api';

export const getUser = params => {
  return dispatch => {
    postLoginData(params)
      .then(response => {
        dispatch({
          type: 'GET_USER_SUCCESS',
          payload: response.data
        });
      })
      .catch(req => {
        dispatch({
          type: 'GET_USER_FAILURE',
          payload: req.response.data
        });
      });
  };
};
