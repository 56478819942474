import axiosGlobalInstance from './axios-instance';
import uriApi from '../config/uriApi';

const getTaxInvoicesTotal = params => {
  const { empresaId } = params;
  return axiosGlobalInstance({
    method: 'GET',
    url: `${uriApi}/api/v1/fiscais/${empresaId}/totalizador`
  });
};

export default getTaxInvoicesTotal;
