export default {
  name: 'custom-theme-simple-solution-financial-app',
  rounding: 24,
  spacing: 20,
  defaultMode: 'light',

  global: {
    colors: {
      icon: {
        dark: 'white',
        light: '#666666'
      },
      active: 'rgba(221,221,221,0.5)',
      black: '#000000',
      border: {
        dark: 'rgba(255,255,255,0.33)',
        light: 'rgba(0,0,0,0.33)'
      },
      brand: '#B81B18',
      control: {
        dark: 'accent-1',
        light: 'brand'
      },
      focus: '#F34642',
      placeholder: '#AAAAAA',
      selected: 'brand',
      text: {
        dark: 'white',
        light: '#444444'
      },
      white: '#FFFFFF',
      'accent-1': '#F34642',
      'accent-2': '#FD6FFF',
      'accent-3': '#7EE96D',
      'accent-4': '#FFCA58',
      'dark-1': '#0E151B',
      'dark-2': '#161F29',
      'dark-3': '#2C3746',
      'dark-4': '#555555',
      'dark-5': '#777777',
      'dark-6': '#999999',
      'light-1': '#F9FAF5',
      'light-2': '#F2F2F2',
      'light-3': '#EDEDED',
      'light-4': '#DADADA',
      'light-5': '#DADADA',
      'light-6': '#DADADA',
      'neutral-1': '#00873D',
      'neutral-2': '#3D138D',
      'neutral-3': '#00739D',
      'neutral-4': '#A2423D',
      'status-critical': '#FF4040',
      'status-error': '#FF4040',
      'status-warning': '#FFAA15',
      'status-ok': '#7EE96D',
      'status-unknown': '#CCCCCC',
      'status-disabled': '#CCCCCC',
      background: '#2C3746'
    },
    animation: {
      duration: '1s',
      jiggle: {
        duration: '0.1s'
      }
    },
    borderSize: {
      xsmall: '1px',
      small: '2px',
      medium: '4px',
      large: '12px',
      xlarge: '24px'
    },
    breakpoints: {
      small: {
        value: 768,
        borderSize: {
          xsmall: '1px',
          small: '2px',
          medium: '4px',
          large: '6px',
          xlarge: '12px'
        },
        edgeSize: {
          none: '0px',
          hair: '1px',
          xxsmall: '2px',
          xsmall: '3px',
          small: '6px',
          medium: '12px',
          large: '24px',
          xlarge: '48px'
        },
        size: {
          xxsmall: '24px',
          xsmall: '48px',
          small: '96px',
          medium: '192px',
          large: '384px',
          xlarge: '768px',
          full: '100%'
        }
      },
      medium: {
        value: 1536
      },
      large: {}
    },
    deviceBreakpoints: {
      phone: 'small',
      tablet: 'medium',
      computer: 'large'
    },
    control: {
      border: {
        width: '1px',
        radius: '24px',
        color: 'border'
      }
    },
    debounceDelay: 300,
    drop: {
      background: '#ffffff',
      border: {
        width: '0px',
        radius: '4px'
      },
      shadowSize: 'small',
      zIndex: '20'
    },
    edgeSize: {
      none: '0px',
      hair: '1px',
      xxsmall: '3px',
      xsmall: '6px',
      small: '12px',
      medium: '24px',
      large: '48px',
      xlarge: '96px',
      responsiveBreakpoint: 'small'
    },
    elevation: {
      light: {
        none: 'none',
        xsmall: '0px 1px 2px rgba(0, 0, 0, 0.20)',
        small: '0px 2px 4px rgba(0, 0, 0, 0.20)',
        medium: '0px 4px 8px rgba(0, 0, 0, 0.20)',
        large: '0px 8px 16px rgba(0, 0, 0, 0.20)',
        xlarge: '0px 12px 24px rgba(0, 0, 0, 0.20)'
      },
      dark: {
        none: 'none',
        xsmall: '0px 2px 2px rgba(255, 255, 255, 0.40)',
        small: '0px 4px 4px rgba(255, 255, 255, 0.40)',
        medium: '0px 6px 8px rgba(255, 255, 255, 0.40)',
        large: '0px 8px 16px rgba(255, 255, 255, 0.40)',
        xlarge: '0px 12px 24px rgba(255, 255, 255, 0.40)'
      }
    },
    focus: {
      border: {
        color: 'focus'
      }
    },
    font: {
      size: '18px',
      height: '24px',
      maxWidth: '432px',
      weight: 500,
      family:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",  "Helvetica Neue", Arial, sans-serif,  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
    },
    hover: {
      background: {
        dark: {
          color: 'active',
          opacity: 'medium'
        },
        light: {
          color: 'active',
          opacity: 'medium'
        }
      },
      color: {
        dark: 'white',
        light: 'black'
      }
    },
    input: {
      padding: '12px',
      weight: 500
    },
    opacity: {
      strong: 0.8,
      medium: 0.4,
      weak: 0.1
    },
    selected: {
      background: 'selected',
      color: 'white'
    },
    spacing: '24px',
    size: {
      xxsmall: '48px',
      xsmall: '96px',
      small: '192px',
      medium: '384px',
      large: '768px',
      xlarge: '1152px',
      xxlarge: '1536px',
      full: '100%'
    }
  },
  table: {
    header: {
      border: 'bottom',
      background: {
        color: 'light-2',
        opacity: 'strong'
      }
    }
  },
  button: {
    border: {
      radius: '24px'
    }
  },
  checkBox: {
    check: {
      radius: '24px'
    },
    toggle: {
      radius: '24px'
    }
  },
  radioButton: {
    check: {
      radius: '24px'
    }
  },
  calendar: {
    small: {
      fontSize: '14px',
      lineHeight: 1.375,
      daySize: '27.428571428571427px'
    },
    medium: {
      fontSize: '18px',
      lineHeight: 1.45,
      daySize: '54.857142857142854px'
    },
    large: {
      fontSize: '30px',
      lineHeight: 1.11,
      daySize: '109.71428571428571px'
    }
  },
  clock: {
    analog: {
      hour: {
        width: '8px',
        size: '24px'
      },
      minute: {
        width: '4px',
        size: '12px'
      },
      second: {
        width: '3px',
        size: '9px'
      },
      size: {
        small: '72px',
        medium: '96px',
        large: '144px',
        xlarge: '216px',
        huge: '288px'
      }
    },
    digital: {
      text: {
        xsmall: {
          size: '10px',
          height: 1.5
        },
        small: {
          size: '14px',
          height: 1.43
        },
        medium: {
          size: '18px',
          height: 1.375
        },
        large: {
          size: '22px',
          height: 1.167
        },
        xlarge: {
          size: '26px',
          height: 1.1875
        },
        xxlarge: {
          size: '34px',
          height: 1.125
        }
      }
    }
  },
  heading: {
    weight: 400,
    level: {
      '1': {
        small: {
          size: '34px',
          height: '40px',
          maxWidth: '816px'
        },
        medium: {
          size: '50px',
          height: '56px',
          maxWidth: '1200px'
        },
        large: {
          size: '82px',
          height: '88px',
          maxWidth: '1968px'
        },
        xlarge: {
          size: '114px',
          height: '120px',
          maxWidth: '2736px'
        }
      },
      '2': {
        small: {
          size: '26px',
          height: '32px',
          maxWidth: '624px'
        },
        medium: {
          size: '34px',
          height: '40px',
          maxWidth: '816px'
        },
        large: {
          size: '50px',
          height: '56px',
          maxWidth: '1200px'
        },
        xlarge: {
          size: '66px',
          height: '72px',
          maxWidth: '1584px'
        }
      },
      '3': {
        small: {
          size: '22px',
          height: '28px',
          maxWidth: '528px'
        },
        medium: {
          size: '26px',
          height: '32px',
          maxWidth: '624px'
        },
        large: {
          size: '34px',
          height: '40px',
          maxWidth: '816px'
        },
        xlarge: {
          size: '42px',
          height: '48px',
          maxWidth: '1008px'
        }
      },
      '4': {
        small: {
          size: '20px',
          height: '24px',
          maxWidth: '432px'
        },
        medium: {
          size: '18px',
          height: '24px',
          maxWidth: '432px'
        },
        large: {
          size: '18px',
          height: '24px',
          maxWidth: '432px'
        },
        xlarge: {
          size: '18px',
          height: '24px',
          maxWidth: '432px'
        }
      },
      '5': {
        small: {
          size: '16px',
          height: '22px',
          maxWidth: '384px'
        },
        medium: {
          size: '16px',
          height: '22px',
          maxWidth: '384px'
        },
        large: {
          size: '16px',
          height: '22px',
          maxWidth: '384px'
        },
        xlarge: {
          size: '16px',
          height: '22px',
          maxWidth: '384px'
        }
      },
      '6': {
        small: {
          size: '14px',
          height: '20px',
          maxWidth: '336px'
        },
        medium: {
          size: '14px',
          height: '20px',
          maxWidth: '336px'
        },
        large: {
          size: '14px',
          height: '20px',
          maxWidth: '336px'
        },
        xlarge: {
          size: '14px',
          height: '20px',
          maxWidth: '336px'
        }
      }
    }
  },
  paragraph: {
    small: {
      size: '14px',
      height: '20px',
      maxWidth: '336px'
    },
    medium: {
      size: '18px',
      height: '24px',
      maxWidth: '432px'
    },
    large: {
      size: '20px',
      height: '28px',
      maxWidth: '528px'
    },
    xlarge: {
      size: '26px',
      height: '32px',
      maxWidth: '624px'
    },
    xxlarge: {
      size: '34px',
      height: '40px',
      maxWidth: '816px'
    }
  },
  text: {
    xsmall: {
      size: '12px',
      height: '18px',
      maxWidth: '288px'
    },
    small: {
      size: '14px',
      height: '20px',
      maxWidth: '336px'
    },
    medium: {
      size: '16px',
      height: '24px',
      maxWidth: '432px'
    },
    large: {
      size: '20px',
      height: '28px',
      maxWidth: '528px'
    },
    xlarge: {
      size: '26px',
      height: '32px',
      maxWidth: '624px'
    },
    xxlarge: {
      size: '34px',
      height: '40px',
      maxWidth: '816px'
    }
  },
  layer: {
    background: 'dark-3'
  }
};
