import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import RoutesMap from './routes-map';
import RouteWrapper from './route-wrapper';

export default function Routes() {
  return (
    <Router>
      <Switch>
        {Object.keys(RoutesMap).map(item => {
          const route = RoutesMap[item];
          return (
            <RouteWrapper
              key={item}
              path={route.path}
              component={route.component}
              isPrivate={route.isPrivate}
            />
          );
        })}
      </Switch>
    </Router>
  );
}
