import axiosGlobalInstance from './axios-instance';
import uriApi from '../config/uriApi';

const getCashRegisters = params => {
  const { empresaId, dataMovimentacao } = params;
  return axiosGlobalInstance({
    method: 'GET',
    url: `${uriApi}/api/v1/caixas/${empresaId}/${dataMovimentacao}`
  });
};

export default getCashRegisters;
