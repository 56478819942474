import { getFinancialTotal, getFinancial } from '../api';

export const loadFinancialTotal = params => {
  return dispatch => {
    getFinancialTotal(params)
      .then(response => {
        dispatch({
          type: 'LOAD_FINANCIAL_TOTAL_SUCCESS',
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: 'LOAD_FINANCIAL_TOTAL_FAILURE',
          payload: error
        });
      });
  };
};

export const loadFinancial = params => {
  return dispatch => {
    getFinancial(params)
      .then(response => {
        dispatch({
          type: 'LOAD_FINANCIAL_LIST_SUCCESS',
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: 'LOAD_FINANCIAL_LIST_FAILURE',
          payload: error
        });
      });
  };
};

export const setFinancialDate = params => {
  return {
    type: 'SET_FINANCIAL_DATE',
    payload: params
  };
};
