import axios from 'axios';
import uriApi from '../config/uriApi';
import LocalStorageService from '../services/localStorageService';

const axiosGlobalInstance = axios.create({ timeout: 30000 });
const localStorageService = LocalStorageService.getService();

axiosGlobalInstance.interceptors.request.use(
  config => {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${localStorageService.getAccessToken()}`
      }
    };
  },
  error => Promise.reject(error)
);

axiosGlobalInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      return axios({
        method: 'post',
        url: `${uriApi}/api/refreshtoken`,
        data: {
          accessToken: localStorageService.getAccessToken(),
          refreshToken: localStorageService.getRefreshToken()
        }
      })
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            localStorageService.setAccessToken(res.data.data.accessToken);
            localStorageService.setRefreshToken(res.data.data.refreshToken);
            return axiosGlobalInstance(originalRequest);
          }
        })
        .catch(error => {
          localStorageService.clearLocalStorage();
          const { location, origin } = window;
          location.replace(origin);
        });
    }
    return Promise.reject(error);
  }
);

export default axiosGlobalInstance;
