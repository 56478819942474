import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'grommet';
import FilterTabs from '../../components/filter-tabs/filter-tabs';
import DataList from '../../components/data-list/data-list';
import formattedDateUi from '../../utils/formatted-date-ui';
import formatMoney from '../../utils/format-money';
import { loadBillsToPay } from '../../actions/cash-registers-actions';
import LocalStorageService from '../../services/localStorageService';

function CashFlowBillsDataList(props) {
  const dispatch = useDispatch();
  const localStorageService = LocalStorageService.getService();

  const companySelected = JSON.parse(localStorageService.getCompanyBranch());
  const billsToPay = useSelector(state => state.cashRegisters.billsToPay);

  useEffect(() => {
    dispatch(
      loadBillsToPay({
        dias: 7,
        empresaId: companySelected.id
      })
    );
  }, [dispatch, companySelected.id]);

  const actionButtonsListCashFlow = [
    {
      id: 1,
      label: 'Contas a pagar',
      active: true,
      actionCallback: () => {}
    }
  ];

  const dataListRows = billsToPay.map(item => {
    const { parcelaId, fornecedor, dataVcto, valor } = item;
    return {
      id: parcelaId,
      name: fornecedor,
      info: formattedDateUi(dataVcto),
      value: formatMoney(valor)
    };
  });

  return (
    <>
      <Box margin={{ top: 'medium' }}>
        <FilterTabs actionButtonsList={actionButtonsListCashFlow} />
      </Box>
      <DataList list={dataListRows} />
    </>
  );
}

CashFlowBillsDataList.defaultProps = {};

CashFlowBillsDataList.propTypes = {};

export default CashFlowBillsDataList;
