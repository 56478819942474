import { getSalesByDate } from '../api';

export const loadSalesByDate = params => {
  return dispatch => {
    getSalesByDate(params)
      .then(response => {
        dispatch({
          type: 'LOAD_SALES_BY_DATE_SUCCESS',
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: 'LOAD_SALES_BY_DATE_FAILURE',
          payload: error
        });
      });
  };
};

export const setRangeOfDays = params => {
  return {
    type: 'SET_RANGE_OF_DAYS',
    payload: params
  };
};
