import axios from 'axios';
import uriApi from '../config/uriApi';

const postLoginData = params => {
  const { email, senha } = params;
  return axios({
    method: 'post',
    url: `${uriApi}/api/login`,
    data: { email, senha }
  });
};

export default postLoginData;
