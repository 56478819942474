import { combineReducers } from 'redux';
import authReducer from './auth-reducer';
import userReducer from './user-reducer';
import companyReducer from './company-reducer';
import cashRegistersReducer from './cash-registers-reducer';
import revenuesReducer from './revenues-reducer';
import salesReducer from './sales-reducer';
import productsReducer from './products-reducer';
import taxInvoicesReducer from './tax-invoices-reducer';
import financialReducer from './financial-reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  company: companyReducer,
  cashRegisters: cashRegistersReducer,
  revenues: revenuesReducer,
  sales: salesReducer,
  products: productsReducer,
  taxInvoices: taxInvoicesReducer,
  financial: financialReducer
});

export default rootReducer;
