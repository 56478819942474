import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsiveContext, Box, Text, Select } from 'grommet';
import { FormDown } from 'grommet-icons';
import moment from 'moment';
import HeadingPage from '../../components/heading/heading-page';
import PageLayout from '../../components/page-layout/page-layout';
import LightContent from '../../components/page-layout/light-content';
import DarkContent from '../../components/page-layout/dark-content';
import CashFlowBillsDataList from './cash-flow-bills-data-list';
import FilterTabs from '../../components/filter-tabs/filter-tabs';
import {
  loadCashRegisters,
  setCashRegister,
  loadLastSynchro,
  loadCashFlow
} from '../../actions/cash-registers-actions';

import formattedDateApi from '../../utils/formatted-date-api';
import formattedTime from '../../utils/formatted-hour';
import formatMoney from '../../utils/format-money';
import CashFlowTabs from './cash-flow-tabs';
import LocalStorageService from '../../services/localStorageService';

function CashFlowBills({ pageTitle }) {
  const dispatch = useDispatch();
  const localStorageService = LocalStorageService.getService();
  const companySelected = JSON.parse(localStorageService.getCompanyBranch());
  const [activeTab, setActiveTab] = useState(1);
  const cashRegister = useSelector(state => state.cashRegisters.cashRegister);
  const lastSynchro = useSelector(state => state.cashRegisters.lastSynchro);
  const cashFlow = useSelector(state => state.cashRegisters.cashFlow);

  const cashRegistersList = useSelector(
    state => state.cashRegisters.cashRegistersList
  );

  const actionTabs = [
    {
      id: 1,
      label: 'Venda',
      active: activeTab === 1,
      actionCallback: () => {
        setActiveTab(1);
      }
    },
    {
      id: 2,
      label: 'Recebimento',
      active: activeTab === 2,
      actionCallback: () => {
        setActiveTab(2);
      }
    },
    {
      id: 3,
      label: 'Supr/Sang',
      active: activeTab === 3,
      actionCallback: () => {
        setActiveTab(3);
      }
    }
  ];

  const onChangeCash = ({ option }) => {
    dispatch(setCashRegister(option));
    dispatch(
      loadLastSynchro({
        empresaId: companySelected.id
      })
    );
  };

  useEffect(() => {
    const today = new Date();

    dispatch(
      loadCashRegisters({
        empresaId: companySelected.id,
        dataMovimentacao: formattedDateApi(today)
      })
    );
  }, [dispatch, companySelected.id]);

  useEffect(() => {
    dispatch(
      loadLastSynchro({
        empresaId: companySelected.id
      })
    );
  }, [dispatch, companySelected.id]);

  useEffect(() => {
    if (cashRegister.id) {
      dispatch(
        loadCashFlow({
          caixaId: cashRegister.id,
          empresaId: companySelected.id
        })
      );
    }
  }, [dispatch, companySelected.id, cashRegister.id]);

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <PageLayout>
          <DarkContent>
            <Box className="mobile-wrapper">
              <HeadingPage text={pageTitle} actionButton={() => {}} />
              <Text
                size="xsmall"
                margin={{ top: '-1.25rem' }}
                weight={300}
                textAlign="center"
              >
                {`Atualizado ${moment(
                  lastSynchro
                ).fromNow()} as ${formattedTime(lastSynchro)}`}
              </Text>
              <Box align="center">
                <Box
                  className="custom-control-radius"
                  margin={{ top: 'small' }}
                  width={{ min: '6rem', max: '13rem' }}
                >
                  <Select
                    options={cashRegistersList}
                    value={cashRegister}
                    labelKey="value"
                    onChange={onChangeCash}
                    margin={{ top: 'small' }}
                    size="xsmall"
                    icon={<FormDown size="1.25rem" />}
                  />
                </Box>
              </Box>
              <Box
                direction="row"
                fill="horizontal"
                justify="evenly"
                pad={{ top: 'large' }}
              >
                <Box pad="xsmall">
                  <Text weight={400} size="xsmall">
                    Saldo do caixa
                  </Text>
                  <Text size="small">{formatMoney(cashFlow.totalCaixa)}</Text>
                </Box>
                <Box pad="xsmall">
                  <Text weight={400} size="xsmall">
                    Total vendido
                  </Text>
                  <Text size="small">{formatMoney(cashFlow.totalVendido)}</Text>
                </Box>
                <Box pad="xsmall">
                  <Text weight={400} size="xsmall">
                    Total recebido
                  </Text>
                  <Text size="small">
                    {formatMoney(cashFlow.totalRecebido)}
                  </Text>
                </Box>
              </Box>
              <Box
                direction="row"
                fill="horizontal"
                justify="evenly"
                pad={{ top: 'small', bottom: 'large' }}
              >
                <Box pad="xsmall">
                  <Text weight={400} size="xsmall">
                    Total a prazo
                  </Text>
                  <Text size="small">{formatMoney(cashFlow.totalPrazo)}</Text>
                </Box>
                <Box pad="xsmall">
                  <Text weight={400} size="xsmall">
                    Suprimentos/Sangrias
                  </Text>
                  <Text size="small">
                    {formatMoney(cashFlow.suprimentoSangria)}
                  </Text>
                </Box>
              </Box>
              <Box margin={{ vertical: 'medium' }}>
                <FilterTabs actionButtonsList={actionTabs} darkMode />
              </Box>

              <Box fill="horizontal">
                <CashFlowTabs activeTab={activeTab} darkMode />
              </Box>
            </Box>
          </DarkContent>
          <LightContent>
            <CashFlowBillsDataList />
          </LightContent>
        </PageLayout>
      )}
    </ResponsiveContext.Consumer>
  );
}

CashFlowBills.propTypes = {
  pageTitle: PropTypes.string.isRequired
};

export default CashFlowBills;
