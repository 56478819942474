import React from 'react';
import { Grommet } from 'grommet';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import promise from 'redux-promise';
import multi from 'redux-multi';
import thunk from 'redux-thunk';
import Routes from '../routes/routes';
import customThemeBrand from '../../config/custom-theme-brand';
import rootReducer from '../../reducers/root-reducer';
import './app.css';

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const store = applyMiddleware(thunk, multi, promise)(createStore)(
  rootReducer,
  devTools
);

export default function App() {
  return (
    <Grommet theme={customThemeBrand} className="grommet-wrapper">
      <Provider store={store}>
        <Routes />
      </Provider>
    </Grommet>
  );
}
