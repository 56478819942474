import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsiveContext, Box, Text, Select } from 'grommet';
import { FormSchedule } from 'grommet-icons';
import HeadingPage from '../../components/heading/heading-page';
import PageLayout from '../../components/page-layout/page-layout';
import DarkContent from '../../components/page-layout/dark-content';
import LightContent from '../../components/page-layout/light-content';
import FilterTabs from '../../components/filter-tabs/filter-tabs';
import BuildSolutionsProgressBar from '../../components/build-solutions-progress-bar/build-solutions-progress-bar';
import formatMoney from '../../utils/format-money';
import FinancialDataList from './financial-data-list';
import LocalStorageService from '../../services/localStorageService';

import {
  setFinancialDate,
  loadFinancialTotal
} from '../../actions/financial-actions';

function Financial(props) {
  const dispatch = useDispatch();
  const mesReferencia = useSelector(state => state.financial.mesReferencia);
  const financialDateOptions = useSelector(
    state => state.financial.financialDateOptions
  );
  const localStorageService = LocalStorageService.getService();
  const companySelected = JSON.parse(localStorageService.getCompanyBranch());
  const totalAbertas = useSelector(state => state.financial.totalAbertas);
  const totalLiquidadas = useSelector(state => state.financial.totalLiquidadas);

  useEffect(() => {
    dispatch(
      loadFinancialTotal({
        empresaId: companySelected.id,
        mesReferencia: mesReferencia.id
      })
    );
  }, [dispatch, companySelected.id, mesReferencia]);

  const actionButtonsFinancial = [
    {
      id: 1,
      label: 'Contas a pagar',
      active: false,
      actionCallback: () => {}
    }
  ];

  const { pageTitle } = props;
  const percentageProgressBarValue = parseInt(
    (totalLiquidadas * 100) / (totalAbertas + totalLiquidadas),
    10
  );

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <PageLayout>
          <DarkContent>
            <Box className="mobile-wrapper">
              <HeadingPage text={pageTitle} actionButton={() => {}} />
              <Box>
                <FilterTabs
                  actionButtonsList={actionButtonsFinancial}
                  darkMode
                />
              </Box>

              <Box align="center">
                <Box
                  className="custom-control-radius"
                  margin={{ top: 'medium' }}
                  width={{ max: '6rem' }}
                >
                  <Select
                    options={financialDateOptions}
                    value={mesReferencia}
                    labelKey="value"
                    valueKey="id"
                    onChange={({ option }) =>
                      dispatch(setFinancialDate(option))
                    }
                    margin={{ vertical: 'small' }}
                    size="xsmall"
                    icon={<FormSchedule size="1.25rem" />}
                  />
                </Box>
              </Box>

              <Box fill="horizontal" margin={{ top: 'small' }}>
                <Box margin={{ vertical: 'medium' }}>
                  <Text textAlign="center" size="small">
                    {`Total no período: ${formatMoney(
                      totalAbertas + totalLiquidadas
                    )}`}
                  </Text>
                </Box>

                <BuildSolutionsProgressBar
                  percentage={percentageProgressBarValue}
                  label={formatMoney(totalLiquidadas)}
                />
              </Box>
            </Box>
          </DarkContent>
          <LightContent>
            <FinancialDataList />
          </LightContent>
        </PageLayout>
      )}
    </ResponsiveContext.Consumer>
  );
}

Financial.propTypes = {
  pageTitle: PropTypes.string.isRequired
};

export default Financial;
