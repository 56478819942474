import axiosGlobalInstance from './axios-instance';
import uriApi from '../config/uriApi';

const getCashFlow = params => {
  const { caixaId, empresaId } = params;
  return axiosGlobalInstance({
    method: 'GET',
    url: `${uriApi}/api/v1/caixas/${caixaId}/${empresaId}/movimentacao`
  });
};

export default getCashFlow;
