import moment from 'moment';

let currentSelectedPeriod = null;
const currentPeriodOptions = [-3, -2, -1, 0, 1, 2].map(date => {
  const dateMonth = moment()
    .add(date, 'month')
    .format('MM-YYYY');
  if (date === 0) currentSelectedPeriod = { id: dateMonth, value: dateMonth };
  return { id: dateMonth, value: dateMonth };
});

const INITIAL_STATE = {
  mesReferencia: currentSelectedPeriod,
  financialDateOptions: currentPeriodOptions,
  totalAbertas: 0,
  totalLiquidadas: 0,
  financialList: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_FINANCIAL_TOTAL_SUCCESS':
      return {
        ...state,
        totalAbertas: action.payload.data.totalAbertas,
        totalLiquidadas: action.payload.data.totalLiquidadas
      };

    case 'LOAD_FINANCIAL_TOTAL_FAILURE':
      return { ...state };

    case 'LOAD_FINANCIAL_LIST_SUCCESS':
      return {
        ...state,
        financialList: action.payload.data
      };

    case 'LOAD_FINANCIAL_LIST_FAILURE':
      return { ...state };

    case 'SET_FINANCIAL_DATE':
      return {
        ...state,
        mesReferencia: action.payload
      };

    default:
      return state;
  }
}
