import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Box } from 'grommet';
import RoutesMap from '../../main/routes/routes-map';

export default function PageLayout(props) {
  const { children } = props;

  const location = useLocation();
  const { pathname } = location;
  let addGutterBottomMenu = false;

  Object.keys(RoutesMap).forEach(item => {
    if (RoutesMap[item].path === pathname)
      addGutterBottomMenu = !RoutesMap[item].layerMenu;
  });

  return (
    <Box
      className="module-wrapper"
      animation="fadeIn"
      pad={addGutterBottomMenu ? { bottom: '54px' } : { bottom: '0' }}
      {...props}
    >
      {children}
    </Box>
  );
}

PageLayout.defaultProps = {
  children: null
};

PageLayout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element)
};
