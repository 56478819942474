import axiosGlobalInstance from './axios-instance';
import uriApi from '../config/uriApi';

const getBillsToPay = params => {
  const { dias, empresaId } = params;
  return axiosGlobalInstance({
    method: 'GET',
    url: `${uriApi}/api/v1/contas-pagar/${dias}/${empresaId}`
  });
};

export default getBillsToPay;
