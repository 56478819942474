import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';

function FilterTabs(props) {
  const [actionButtonsList, setActionButtonsList] = useState(
    props.actionButtonsList
  );

  const handleActionButton = actionButton => {
    actionButton.actionCallback(actionButton);

    const newActionButtonsList = actionButtonsList.map(element => {
      const newElement = { ...element };
      if (newElement.id === actionButton.id) newElement.active = true;
      else newElement.active = false;
      return newElement;
    });

    setActionButtonsList(newActionButtonsList);
  };
  const { darkMode } = props;
  const colorMode = darkMode ? 'accent-1' : 'brand';

  if (Array.isArray(actionButtonsList) && actionButtonsList.length > 1)
    return (
      <Box
        border={{ color: colorMode, size: '1px' }}
        round="large"
        direction="row"
        justify="between"
        className="filter-tabs"
      >
        {actionButtonsList.map(actionButton => {
          const isItemActive = actionButton.active;
          return (
            <Box
              flex={{ grow: 1 }}
              align="center"
              round="large"
              pad={{ vertical: '0.5rem', horizontal: 'large' }}
              hoverIndicator={false}
              background={isItemActive ? colorMode : 'transparent'}
              border={{ size: '0' }}
              onClick={() => {
                handleActionButton(actionButton);
              }}
              className="filter-tabs-item"
              key={actionButton.id}
            >
              <Text
                size="0.75rem"
                color={isItemActive ? 'white' : colorMode}
                weight={700}
              >
                {actionButton.label}
              </Text>
            </Box>
          );
        })}
      </Box>
    );
  if (Array.isArray(actionButtonsList) && actionButtonsList.length === 1) {
    const [actionButton] = actionButtonsList;
    return (
      <Box
        round="large"
        direction="row"
        justify="center"
        className="filter-tabs"
      >
        <Text color={colorMode}>{actionButton.label}</Text>
      </Box>
    );
  }
  return null;
}

FilterTabs.defaultProps = {
  actionButtonsList: [],
  darkMode: false
};

FilterTabs.propTypes = {
  actionButtonsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      active: PropTypes.bool,
      actionCallback: PropTypes.func
    })
  ),
  darkMode: PropTypes.bool
};

export default FilterTabs;
