import axiosGlobalInstance from './axios-instance';
import uriApi from '../config/uriApi';

const getLastSynchro = params => {
  const { empresaId } = params;
  return axiosGlobalInstance({
    method: 'GET',
    url: `${uriApi}/api/status/${empresaId}`
  });
};

export default getLastSynchro;
