import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text } from 'grommet';
import FilterTabs from '../../components/filter-tabs/filter-tabs';
import DataList from '../../components/data-list/data-list';
import formattedDateUi from '../../utils/formatted-date-ui';
import formatMoney from '../../utils/format-money';
import {
  loadTaxInvoicesNfe,
  loadTaxInvoicesCfe
} from '../../actions/tax-invoices-actions';
import LocalStorageService from '../../services/localStorageService';

function TaxInvoicesDataList(props) {
  const [selectedTab, setSelectedTab] = useState(1);
  const dispatch = useDispatch();
  const localStorageService = LocalStorageService.getService();
  const companySelected = JSON.parse(localStorageService.getCompanyBranch());
  const taxInvoicesList = useSelector(
    state => state.taxInvoices.taxInvoicesList
  );

  useEffect(() => {
    dispatch(
      loadTaxInvoicesNfe({
        empresaId: companySelected.id
      })
    );
  }, [dispatch, companySelected.id]);

  const actionButtonsTaxInvoices = [
    {
      id: 1,
      label: 'Notas fiscais',
      active: true,
      actionCallback: data => {
        setSelectedTab(1);
        dispatch(
          loadTaxInvoicesNfe({
            empresaId: companySelected.id
          })
        );
      }
    },
    {
      id: 2,
      label: 'CF-es',
      active: false,
      actionCallback: data => {
        setSelectedTab(0);

        dispatch(
          loadTaxInvoicesCfe({
            empresaId: companySelected.id
          })
        );
      }
    }
  ];

  const dataListRows = taxInvoicesList.map(item => {
    const { numero, destinatario, dataEmissao, total } = item;
    return {
      id: numero,
      name: destinatario,
      info: formattedDateUi(dataEmissao),
      value: formatMoney(total)
    };
  });

  const currentInvoices = props.invoices[selectedTab];

  return (
    <>
      <Box margin={{ top: 'medium' }}>
        <FilterTabs actionButtonsList={actionButtonsTaxInvoices} />
      </Box>
      {currentInvoices && (
        <Box margin={{ top: 'medium' }}>
          <Text weight={400} size="small" alignSelf="center">
            Total {currentInvoices.tipo}: {formatMoney(currentInvoices.total)}
          </Text>
        </Box>
      )}
      <DataList list={dataListRows} />
    </>
  );
}

TaxInvoicesDataList.defaultProps = {};

TaxInvoicesDataList.propTypes = {};

export default TaxInvoicesDataList;
