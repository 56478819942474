import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Heading, Box } from 'grommet';
import { FormPrevious } from 'grommet-icons';
import RoutesMap from '../../main/routes/routes-map';

import './heading-page.css';

export default function HeadingPage(props) {
  const { text } = props;
  const history = useHistory();

  const location = useLocation();
  const { pathname } = location;
  let showGoBackNavButton = false;

  Object.keys(RoutesMap).forEach(item => {
    if (RoutesMap[item].path === pathname)
      showGoBackNavButton = RoutesMap[item].layerMenu;
  });

  return (
    <Box
      direction="row"
      margin={{ vertical: 'large' }}
      fill="horizontal"
      justify="center"
      className="heading-page"
      align="center"
    >
      {showGoBackNavButton && (
        <FormPrevious
          color="white"
          size="medium"
          className="heading-page-prev"
          onClick={() => history.goBack()}
        />
      )}

      <Heading level="4" size="small" margin="none">
        {text}
      </Heading>
    </Box>
  );
}

HeadingPage.defaultProps = {
  text: ''
};
HeadingPage.propTypes = {
  text: PropTypes.string
};
