const date = new Date();
const y = date.getFullYear();
const m = date.getMonth();
const intialDate = new Date(y, m, 1);
const finalDate = new Date(y, m + 1, 0);

const INITIAL_STATE = {
  produtctsForSale: true,
  dataInicial: intialDate,
  dataFinal: finalDate,
  productsList: [{}],
  orderBy: 'quantidade'
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_PRODUCTS_SUCCESS':
      return {
        ...state,
        productsList: action.payload.data
      };

    case 'LOAD_PRODUCTS_FAILURE':
      return { ...state };

    case 'SET_PRODUCTS_DATE':
      return {
        ...state,
        dataInicial: action.payload.from,
        dataFinal: action.payload.to
      };

    case 'SET_ORDER_BY_PRODUCTS':
      return {
        ...state,
        orderBy: action.payload
      };

    case 'SET_PRODUCTS_SALE':
      return {
        ...state,
        produtctsForSale: action.payload
      };

    default:
      return state;
  }
}
