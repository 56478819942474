import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsiveContext, Box } from 'grommet';
import HeadingPage from '../../components/heading/heading-page';
import PageLayout from '../../components/page-layout/page-layout';
import DarkContent from '../../components/page-layout/dark-content';
import LightContent from '../../components/page-layout/light-content';
import FilterTabs from '../../components/filter-tabs/filter-tabs';
import SoldedProductsDataList from './solded-products-data-list';
import CanceledProductsDataList from './canceled-products-data-list';
import CalendarDropButton from '../../components/calendar-drop-button/calendar-drop-button';

import {
  setProductsSale,
  setProductsDate
} from '../../actions/products-actions';

function Products(props) {
  const dispatch = useDispatch();
  const dataInicial = useSelector(state => state.products.dataInicial);
  const dataFinal = useSelector(state => state.products.dataFinal);
  const produtctsForSale = useSelector(
    state => state.products.produtctsForSale
  );

  const actionButtonsProducts = [
    {
      id: 1,
      label: 'Vendidos',
      active: produtctsForSale,
      actionCallback: () => {
        dispatch(setProductsSale(true));
      }
    },
    {
      id: 2,
      label: 'Cancelados',
      active: !produtctsForSale,
      actionCallback: () => {
        dispatch(setProductsSale(false));
      }
    }
  ];

  const onSelectDate = date => {
    dispatch(setProductsDate(date));
  };

  const { pageTitle } = props;
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <PageLayout
          animation={{
            type: 'slideUp',
            size: 'xlarge',
            duration: 750
          }}
        >
          <DarkContent>
            <Box className="mobile-wrapper">
              <HeadingPage text={pageTitle} actionButton={() => {}} />
              <Box margin={{ vertical: 'medium' }}>
                <FilterTabs
                  actionButtonsList={actionButtonsProducts}
                  darkMode
                />
              </Box>
              <Box margin={{ vertical: 'medium' }}>
                <CalendarDropButton
                  from={dataInicial}
                  to={dataFinal}
                  onSelectDate={onSelectDate}
                />
              </Box>
            </Box>
          </DarkContent>
          <LightContent>
            <Box className="mobile-wrapper">
              {produtctsForSale ? (
                <SoldedProductsDataList />
              ) : (
                <CanceledProductsDataList />
              )}
            </Box>
          </LightContent>
        </PageLayout>
      )}
    </ResponsiveContext.Consumer>
  );
}

Products.propTypes = {
  pageTitle: PropTypes.string.isRequired
};

export default Products;
