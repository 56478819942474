import React from 'react';

import { DocumentPerformance, Notes, Money, Tag } from 'grommet-icons';
import Login from '../../modules/login/login';
import CompanyBranches from '../../modules/company-branches/company-branches';
import CashFlowBills from '../../modules/cash-flow-bills/cash-flow-bills';
import Revenues from '../../modules/revenues/revenues';
import Sales from '../../modules/sales/sales';
import Financial from '../../modules/financial/financial';
import Products from '../../modules/products/products';
import TaxInvoices from '../../modules/tax-invoices/tax-invoices';

function RoutesMap() {
  return {
    login: {
      path: '/login',
      linkText: 'Login',
      primaryMenu: null,
      layerMenu: false,
      showNavigation: false,
      isPrivate: false,
      component: <Login pageTitle="" />
    },
    companyBranches: {
      path: '/unidades',
      linkText: 'Unidades',
      primaryMenu: null,
      layerMenu: false,
      showNavigation: false,
      isPrivate: false,
      component: <CompanyBranches pageTitle="Unidades" />
    },
    cashFlowBills: {
      path: '/fluxo-de-caixa',
      linkText: 'Resumo',
      primaryMenu: { icon: props => <DocumentPerformance {...props} /> },
      layerMenu: false,
      showNavigation: true,
      isPrivate: true,
      component: <CashFlowBills pageTitle="Resumo de caixa e Contas" />
    },
    revenues: {
      path: '/receitas',
      linkText: 'Fluxo',
      primaryMenu: { icon: props => <Notes {...props} /> },
      layerMenu: false,
      showNavigation: true,
      isPrivate: true,
      component: <Revenues pageTitle="Fluxo de caixa" />
    },
    sales: {
      path: '/vendas',
      linkText: 'Vendas',
      primaryMenu: { icon: props => <Tag {...props} /> },
      layerMenu: false,
      showNavigation: true,
      isPrivate: true,
      component: <Sales pageTitle="Vendas" />
    },
    financial: {
      path: '/financeiro',
      linkText: 'Financeiro',
      primaryMenu: { icon: props => <Money {...props} /> },
      layerMenu: false,
      showNavigation: true,
      isPrivate: true,
      component: <Financial pageTitle="Financeiro" />
    },
    products: {
      path: '/produtos',
      linkText: 'Produtos',
      primaryMenu: null,
      layerMenu: true,
      showNavigation: false,
      isPrivate: true,
      component: <Products pageTitle="Produtos" />
    },
    taxInvoices: {
      path: '/fiscal',
      linkText: 'Fiscal',
      primaryMenu: null,
      layerMenu: true,
      showNavigation: false,
      isPrivate: true,
      component: <TaxInvoices pageTitle="Documentos fiscais" />
    },
    root: {
      path: '/',
      linkText: 'Login',
      primaryMenu: null,
      layerMenu: false,
      showNavigation: false,
      isPrivate: false,
      component: <Login pageTitle="" />
    }
  };
}

export default new RoutesMap();
