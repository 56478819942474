const INITIAL_STATE = {
  token: '',
  refreshToken: '',
  errors: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_USER_SUCCESS': {
      const { accessToken, refreshToken } = action.payload.data;
      return { ...state, token: accessToken, refreshToken };
    }
    case 'GET_USER_FAILURE': {
      return {
        ...state,
        errors: ['Email ou senha incorretos']
      };
    }
    default:
      return state;
  }
}
