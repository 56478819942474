import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContext, Box, Text } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import HeadingPage from '../../components/heading/heading-page';
import PageLayout from '../../components/page-layout/page-layout';
import DarkContent from '../../components/page-layout/dark-content';
import LightContent from '../../components/page-layout/light-content';
import TaxInvoicesDataList from './tax-invoices-data-list';
import BuildSolutionsBarChart from '../../components/build-solutions-bar-chart/build-solutions-bar-chart';
import { loadTaxInvoicesTotal } from '../../actions/tax-invoices-actions';
import formatMoney from '../../utils/format-money';
import LocalStorageService from '../../services/localStorageService';

function TaxInvoices(props) {
  const dispatch = useDispatch();
  const localStorageService = LocalStorageService.getService();
  const companySelected = JSON.parse(localStorageService.getCompanyBranch());
  const taxInvoicesTotal = useSelector(
    state => state.taxInvoices.taxInvoicesTotal
  );

  const month = taxInvoicesTotal.length ? taxInvoicesTotal[0].emissao : 0;

  useEffect(() => {
    dispatch(
      loadTaxInvoicesTotal({
        empresaId: companySelected.id
      })
    );
  }, [dispatch, companySelected.id]);

  const { pageTitle } = props;
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <PageLayout
          animation={{
            type: 'slideUp',
            size: 'xlarge',
            duration: 750
          }}
        >
          <DarkContent>
            <Box className="mobile-wrapper">
              <HeadingPage text={pageTitle} actionButton={() => {}} />
              <Box pad="xsmall">
                <Text weight={400} size="small" alignSelf="center">
                  Total fiscal {moment(month).format('MMMM')}:{' '}
                  {formatMoney(
                    taxInvoicesTotal.reduce((acc, x) => x.total + acc, 0)
                  )}
                </Text>
              </Box>
              <Box margin={{ vertical: 'medium' }}>
                <BuildSolutionsBarChart
                  data={taxInvoicesTotal}
                  indexBy="tipo"
                  keys={['total']}
                  tooltip={val => (
                    <div>Total: {formatMoney(val.data.total)}</div>
                  )}
                />
              </Box>
            </Box>
          </DarkContent>
          <LightContent>
            <Box className="mobile-wrapper">
              <TaxInvoicesDataList invoices={taxInvoicesTotal} />
            </Box>
          </LightContent>
        </PageLayout>
      )}
    </ResponsiveContext.Consumer>
  );
}

TaxInvoices.propTypes = {
  pageTitle: PropTypes.string.isRequired
};

export default TaxInvoices;
