import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

export default function LightContent(props) {
  const { children } = props;
  return (
    <Box
      height={{ min: 'auto' }}
      flex={{ grow: 1, shrink: 0 }}
      pad={{ vertical: 'small', horizontal: 'large' }}
      background="white"
    >
      {children}
    </Box>
  );
}

LightContent.defaultProps = {
  children: null
};

LightContent.propTypes = {
  children: PropTypes.element
};
