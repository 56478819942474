import {
  getCashRegisters,
  getLastSynchro,
  getCashFlow,
  getBillsToPay
} from '../api';
import formatOptionsFromApi from '../utils/formatOptionsFromApi';

export const loadCashRegisters = params => {
  return dispatch => {
    getCashRegisters(params)
      .then(response => {
        const formattedOptions = formatOptionsFromApi(response.data.data);
        dispatch({
          type: 'LOAD_CASH_REGISTERS_SUCCESS',
          payload: { data: formattedOptions }
        });
      })
      .catch(error => {
        dispatch({
          type: 'LOAD_CASH_REGISTERS_FAILURE',
          payload: error
        });
      });
  };
};

export const loadCashFlow = params => {
  return dispatch => {
    getCashFlow(params)
      .then(response => {
        dispatch({
          type: 'LOAD_CASH_FLOW_SUCCESS',
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: 'LOAD_CASH_FLOW_FAILURE',
          payload: error
        });
      });
  };
};

export const loadLastSynchro = params => {
  return dispatch => {
    getLastSynchro(params)
      .then(response => {
        dispatch({
          type: 'GET_LAST_SYNCHRO_SUCCESS',
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: 'LOAD_CASH_REGISTERS_FAILURE',
          payload: error
        });
      });
  };
};

export const loadBillsToPay = params => {
  return dispatch => {
    getBillsToPay(params)
      .then(response => {
        dispatch({
          type: 'LOAD_BILLS_TO_PAY_SUCCESS',
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: 'LOAD_BILLS_TO_PAY_FAILURE',
          payload: error
        });
      });
  };
};

export const setCashRegister = params => {
  return {
    type: 'SET_CASH_REGISTER',
    payload: params
  };
};
