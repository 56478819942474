import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'grommet';
import FilterTabs from '../../components/filter-tabs/filter-tabs';
import DataList from '../../components/data-list/data-list';
import formattedDateUi from '../../utils/formatted-date-ui';
import formatMoney from '../../utils/format-money';
import { loadFinancial } from '../../actions/financial-actions';
import LocalStorageService from '../../services/localStorageService';

function TaxInvoicesDataList(props) {
  const dispatch = useDispatch();
  const localStorageService = LocalStorageService.getService();
  const companySelected = JSON.parse(localStorageService.getCompanyBranch());
  const financialList = useSelector(state => state.financial.financialList);

  useEffect(() => {
    dispatch(
      loadFinancial({
        empresaId: companySelected.id,
        dias: 7
      })
    );
  }, [dispatch, companySelected.id]);

  const actionButtonsFinancial = [
    {
      id: 1,
      label: 'Vencidas',
      active: true,
      actionCallback: () => {}
    }
  ];

  const dataListRows = financialList.map(item => {
    const { parcelaId, fornecedor, dataVcto, valor } = item;
    return {
      id: parcelaId,
      name: fornecedor,
      info: formattedDateUi(dataVcto),
      value: formatMoney(valor)
    };
  });

  return (
    <>
      <Box margin={{ top: 'medium' }}>
        <FilterTabs actionButtonsList={actionButtonsFinancial} />
      </Box>
      <DataList list={dataListRows} />
    </>
  );
}

TaxInvoicesDataList.defaultProps = {};

TaxInvoicesDataList.propTypes = {};

export default TaxInvoicesDataList;
