const INITIAL_STATE = {
  salesListSummary: [{}],
  salesListComparative: [{}],
  rangeOfDays: { id: 7, value: 'Últimos 7 dias' }
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_SALES_BY_DATE_SUCCESS': {
      return {
        ...state,
        salesListSummary: action.payload.data.resumo,
        salesListComparative: action.payload.data.comparativo
      };
    }
    case 'LOAD_SALES_BY_DATE_FAILURE':
      return { ...state };

    case 'SET_RANGE_OF_DAYS': {
      return { ...state, rangeOfDays: action.payload };
    }
    default:
      return state;
  }
}
