const INITIAL_STATE = {
  revenuesList: [],
  currentMountRevenues: {
    anoMes: '',
    vendas: '00.00',
    recebimentos: '00.00',
    saidas: '00.00'
  }
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_REVENUES_SUCCESS': {
      const revenuesList = [...action.payload.data.data];
      const currentMountRevenues = revenuesList.pop();
      return {
        ...state,
        revenuesList: [currentMountRevenues, ...revenuesList],
        currentMountRevenues
      };
    }
    case 'UPDATE_CURRENT_REVENUES': {
      return {
        ...state,
        currentMountRevenues: action.payload
      };
    }
    case 'LOAD_REVENUES_FAILURE':
      return { ...state };

    default:
      return state;
  }
}
