import taxInvoicesTypes from '../constants/tax-invoices-types';

const INITIAL_STATE = {
  taxInvoicesType: taxInvoicesTypes.NOTA_FISCAL_ELETRONICA,
  taxInvoicesList: [],
  taxInvoicesTotal: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_TAX_INVOICES_NFE_SUCCESS':
      return {
        ...state,
        taxInvoicesList: action.payload.data
      };

    case 'LOAD_TAX_INVOICES_NFE_FAILURE':
      return { ...state };

    case 'LOAD_TAX_INVOICES_CFE_SUCCESS':
      return {
        ...state,
        taxInvoicesList: action.payload.data
      };

    case 'LOAD_TAX_INVOICES_CFE_FAILURE':
      return { ...state };

    case 'LOAD_TAX_INVOICES_TOTAL_SUCCESS':
      return {
        ...state,
        taxInvoicesTotal: action.payload.data
      };

    case 'LOAD_TAX_INVOICES_TOTAL_FAILURE':
      return { ...state };

    case 'SET_TAX_INVOICES_TYPE':
      return {
        ...state,
        taxInvoicesType: action.payload
      };

    default:
      return state;
  }
}
