const INITIAL_STATE = {
  companyId: '',
  companyCNPJ: '',
  companyName: ''
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_CURRENT_COMPANY': {
      const { id, cnpj, razaoSocial } = action.payload;
      return {
        ...state,
        companyId: id,
        companyCNPJ: cnpj,
        companyName: razaoSocial
      };
    }
    default:
      return state;
  }
}
