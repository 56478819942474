import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ResponsiveContext, Layer, Box, Text, Button, Heading } from 'grommet';
import { FormClose, Transaction } from 'grommet-icons';
import RoutesMap from '../../main/routes/routes-map';
import LocalStorageService from '../../services/localStorageService';

import './layer-menu.css';

function LayerMenu(props) {
  const history = useHistory();
  const { openLayerMenu, onCloseLayerMenu } = props;
  const localStorageService = LocalStorageService.getService();

  const redirectMenuRoutes = (route, closeLayer) => {
    if (closeLayer) onCloseLayerMenu();
    history.push(route);
  };

  const handleLogout = () => {
    localStorageService.clearLocalStorage();
    const { location, origin } = window;
    location.replace(origin);
  };

  const user = JSON.parse(localStorageService.getUserInfo());
  const companySelected = JSON.parse(localStorageService.getCompanyBranch());

  const { name: userName } = user;
  const { cnpj: companyCNPJ, razaoSocial: companyName } = companySelected;

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          openLayerMenu && (
            <Layer
              position="right"
              full="vertical"
              onClickOutside={onCloseLayerMenu}
              onEsc={onCloseLayerMenu}
              responsive={false}
              modal
            >
              <Box
                fill="vertical"
                overflow="auto"
                width={size === 'small' ? '80vw' : 'medium'}
                direction="column"
                justify="between"
              >
                <Box pad="medium">
                  <Box
                    flex={false}
                    direction="row"
                    justify="between"
                    align="center"
                    margin={{ top: 'medium', bottom: 'large' }}
                  >
                    <Heading level={2} margin="none" color="dark-5">
                      Mais
                    </Heading>
                    <Button icon={<FormClose />} onClick={onCloseLayerMenu} />
                  </Box>
                  <Box className="layer-menu">
                    {Object.keys(RoutesMap).map((item, index) => {
                      const route = RoutesMap[item];
                      if (!route.layerMenu) return null;
                      return (
                        <Box
                          margin={{ top: 'medium', bottom: 'medium' }}
                          key={item}
                        >
                          <Button
                            plain
                            onClick={() =>
                              redirectMenuRoutes(route.path, false)
                            }
                            className="uppercase-button"
                          >
                            {route.linkText}
                          </Button>
                        </Box>
                      );
                    })}

                    <Box margin={{ top: 'large', bottom: 'medium' }}>
                      <Button plain onClick={handleLogout}>
                        Sair
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box
                  border={{ color: 'dark-5', size: 'xsmall', side: 'top' }}
                  margin={{ left: 'xlarge' }}
                  pad={{ vertical: 'large', right: 'large' }}
                  align="start"
                >
                  <Text
                    size="large"
                    weight={300}
                    margin={{ bottom: '0.25rem' }}
                  >
                    {userName}
                  </Text>
                  <Text size="small" margin={{ bottom: '0.25rem' }}>
                    {companyName}
                  </Text>
                  <Text size="xsmall" margin={{ bottom: '1rem' }}>
                    CNPJ: {companyCNPJ}
                  </Text>
                  <Button
                    label="TROCAR UNIDADE"
                    icon={<Transaction size="1.2rem" />}
                    onClick={() => redirectMenuRoutes('/unidades', true)}
                    primary
                    reverse
                    className="uppercase-button"
                    color="brand"
                  />
                </Box>
              </Box>
            </Layer>
          )
        );
      }}
    </ResponsiveContext.Consumer>
  );
}

const mapStateToProps = state => ({
  userName: state.user.name,
  companyName: state.company.companyName,
  companyCNPJ: state.company.companyCNPJ
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

LayerMenu.propTypes = {
  userName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  companyCNPJ: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerMenu);
