import { getRevenues } from '../api';

export const loadRevenues = params => {
  return dispatch => {
    getRevenues(params)
      .then(response => {
        dispatch({
          type: 'LOAD_REVENUES_SUCCESS',
          payload: response
        });
      })
      .catch(error => {
        dispatch({
          type: 'LOAD_REVENUES_FAILURE',
          payload: error
        });
      });
  };
};

export const updateCurrentRevenues = params => {
  return {
    type: 'UPDATE_CURRENT_REVENUES',
    payload: params
  };
};
