import { getUser } from '../api';

export const loadUserData = () => {
  return dispatch => {
    getUser()
      .then(response => {
        dispatch({
          type: 'LOAD_USER_DATA_SUCCESS',
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: 'LOAD_USER_DATA_FAILURE',
          payload: error
        });
      });
  };
};
