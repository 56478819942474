import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ResponsiveContext, Box, Text } from 'grommet';
import HeadingPage from '../../components/heading/heading-page';
import PageLayout from '../../components/page-layout/page-layout';
import DarkContent from '../../components/page-layout/dark-content';
import {
  loadRevenues,
  updateCurrentRevenues
} from '../../actions/revenues-actions';
import BuildSolutionsBarChart from '../../components/build-solutions-bar-chart/build-solutions-bar-chart';
import StatusIndicator from '../../components/status-indicator/status-indicator';
import formatMoney from '../../utils/format-money';
import LocalStorageService from '../../services/localStorageService';

function Revenues(props) {
  const { pageTitle } = props;
  const dispatch = useDispatch();
  const localStorageService = LocalStorageService.getService();
  const companySelected = JSON.parse(localStorageService.getCompanyBranch());
  const revenuesList = useSelector(state => state.revenues.revenuesList);

  const currentMountRevenues = useSelector(
    state => state.revenues.currentMountRevenues
  );

  useEffect(() => {
    dispatch(loadRevenues({ empresaId: companySelected.id }));
  }, [dispatch, companySelected.id]);

  const updateRevenues = ({ data }) => dispatch(updateCurrentRevenues(data));

  const statusIndicator = currentMountRevenues.saldo > 0;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <PageLayout>
          <DarkContent>
            <Box className="mobile-wrapper">
              <HeadingPage text={pageTitle} actionButton={() => {}} />
              <Box
                direction="row"
                fill="horizontal"
                justify="evenly"
                pad={{ vertical: 'small' }}
              >
                <Box pad="xsmall" fill>
                  <Text weight={400} size="xsmall" textAlign="center">
                    Vendas
                  </Text>
                  <Text size="small" textAlign="center">
                    {formatMoney(currentMountRevenues.vendas)}
                  </Text>
                </Box>
                <Box pad="xsmall" fill>
                  <Text weight={400} size="xsmall" textAlign="center">
                    Recebimentos
                  </Text>
                  <Text size="small" textAlign="center">
                    {formatMoney(currentMountRevenues.recebimentos)}
                  </Text>
                </Box>
              </Box>

              <Box
                direction="row"
                fill="horizontal"
                justify="evenly"
                pad={{ vertical: 'small' }}
              >
                <Box pad="xsmall" fill>
                  <Text weight={400} size="xsmall" textAlign="center">
                    Saídas
                  </Text>
                  <Text size="small" textAlign="center">
                    {formatMoney(currentMountRevenues.saidas)}
                  </Text>
                </Box>
                <Box pad="xsmall" fill>
                  <Text weight={400} size="xsmall" textAlign="center">
                    Saldo
                  </Text>
                  <Text size="small" textAlign="center">
                    {formatMoney(currentMountRevenues.saldo)}
                  </Text>
                </Box>
              </Box>

              <Box margin={{ vertical: 'large' }}>
                <StatusIndicator positive={statusIndicator} />
              </Box>
              <BuildSolutionsBarChart
                data={revenuesList}
                keys={['saldo']}
                indexBy="anoMes"
                onClick={updateRevenues}
              />
            </Box>
          </DarkContent>
        </PageLayout>
      )}
    </ResponsiveContext.Consumer>
  );
}

Revenues.propTypes = {
  pageTitle: PropTypes.string.isRequired
};

export default Revenues;
