import axiosGlobalInstance from './axios-instance';
import uriApi from '../config/uriApi';

const getRevenues = params => {
  const { empresaId } = params;
  return axiosGlobalInstance({
    method: 'GET',
    url: `${uriApi}/api/v1/receitas/${empresaId}`
  });
};

export default getRevenues;
