import axiosGlobalInstance from './axios-instance';
import uriApi from '../config/uriApi';

const getTaxInvoicesCfe = params => {
  const { empresaId } = params;
  return axiosGlobalInstance({
    method: 'GET',
    url: `${uriApi}/api/v1/fiscais/${empresaId}/cfes`
  });
};

export default getTaxInvoicesCfe;
